import React, { useRef, useState, useEffect } from 'react';
import { FaPaperPlane, FaRobot } from 'react-icons/fa';
import { jsonResponse } from './response.js';
import './chatbot.component.css';

export function Chatbot({ context }) {
	const newMessageRef = useRef();
	const [response, setResponse] = useState(jsonResponse);
	const [prompt, setPrompt] = useState('');

	useEffect(() => {
		setResponse((response) => [response[0]]);
	}, [context]);

	const handleSendMessage = (e = null) => {
		e && e.preventDefault();
		const message = newMessageRef.current.value;

		if (!message) return;

		setResponse((response) => [
			...response,
			{ id: Math.random() * 1000, type: 'user', message },
		]);

		//   Empty the value here
		newMessageRef.current.value = '';

		//   scroll to bottom
		var objDiv = document.getElementsByClassName(
			'chatbot__main-chatScreen'
		);

		//   Here call the API
		fetch('https://api.9tee6.com/chatbot', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ question: message, prompt, context }),
		})
			.then((response) =>
				response.json().then((res) => {
					setResponse((response) => [
						...response,
						{
							id: Math.random() * 1000,
							type: 'bot',
							message: res.data.text,
						},
					]);

					setPrompt(res.message);
					objDiv[0].scrollTop = objDiv[0].scrollHeight;
				})
			)
			.catch((error) => console.log('error--', error));
	};

	useEffect(() => {
		var objDiv = document.getElementsByClassName(
			'chatbot__main-chatScreen'
		);
		objDiv[0].scrollTop = objDiv[0].scrollHeight;
	}, [response]);

	return response ? (
		<div className='chatbot__main-div'>
			<div className='chatbot__main-header'>
				<h4>Chat with us</h4>
			</div>
			<div className='chatbot__main-chatScreen'>
				<div className='chatbot__main-chatDialogues'>
					{response.map((chat, index) =>
						chat.type === 'bot' ? (
							<div
								key={Math.random() * 1000}
								id='chatbot__main-botDialogue'
								className='chatbot__dialog'>
								<div id='chatbot__main-botIcon'>
									<div id='chatbot__main-online'></div>
									<FaRobot />
								</div>
								<div
									id='chatbot__main-botMessage'
									className='chatbot__dialog_message'
									style={
										response[index + 1] &&
										response[index + 1].type === 'bot'
											? {
													borderBottomLeftRadius: 0,
											  }
											: {}
									}>
									<p>{chat.message}</p>
								</div>
							</div>
						) : (
							<div
								key={Math.random() * 1000}
								className='chatbot__main-userDialougsContainer'
								style={
									response[index - 1] &&
									response[index - 1].type === 'bot'
										? {
												marginTop: '10px',
										  }
										: {}
								}>
								<div
									id='chatbot__main-userDialogue'
									className='chatbot__dialog'>
									<div
										id='chatbot__main-userMessage'
										className='chatbot__dialog_message'
										style={
											response[index - 1] &&
											response[index - 1].type === 'bot'
												? {
														borderTopRightRadius:
															'20px',
												  }
												: response[index + 1] &&
												  response[index + 1].type ===
														'bot'
												? {
														borderBottomRightRadius:
															'20px',
												  }
												: !response[index + 1]
												? {
														borderBottomRightRadius:
															'20px',
												  }
												: {}
										}>
										<p>{chat.message}</p>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
			<div className='chatbot__main-formDiv'>
				<form
					action='/'
					method='post'
					onSubmit={(e) => handleSendMessage(e)}>
					<div className='chatbot__main-newMessagediv'>
						<div>
							<textarea
								type='text'
								name='newMessage'
								id='newMessage'
								placeholder='Type your message...'
								ref={newMessageRef}
								rows='1'></textarea>
						</div>
						<div
							id='chatbot__main-paperIcon'
							onClick={() => handleSendMessage()}>
							<FaPaperPlane />
						</div>
					</div>
				</form>
			</div>
		</div>
	) : (
		'Loading Chats...'
	);
}
