import React, { useState, useRef } from 'react';

import { Chatbot } from './Chatbot/chatbot.component';

const App = () => {
	const [context, setContext] = useState(
		'This chat is related to my online Restaurant. Here we make the best Indian, chinese, italian, punjabi, south Indian, North-Indian Food. Our speciality is the Indian Food. We make almost all Indian dishes. Our Paneer is the best. We also make fluffy idlis which are smooth like cotton. Our thin crust pizza and cheese burst pizza is the most selling in italian dishes. And in Indian dishes our butter chicken is our speciality. We make butter chicken with the authentic ingredients.'
	);
	const [btnText, setBtnText] = useState('Update Context');
	const contextRef = useRef();

	const updateContext = () => {
		setBtnText('Updated');
		setContext(contextRef.current.value);
		setTimeout(() => {
			setBtnText('Update Context');
		}, 2000);
	};

	return (
		<>
			<div style={{ marginLeft: '20px', marginTop: '20px' }}>
				<textarea
					defaultValue={context}
					style={{ width: '40em' }}
					ref={contextRef}
					rows='10'></textarea>
				<br />
				<button id='updateBtn' onClick={() => updateContext()}>
					{btnText}
				</button>
				<br />
				<p>
					It's a GPT based Chatbot which replies like a human. 
					Enter any context of the chat, and ask questions in the
					chatbox.
				</p>

			</div>
			<Chatbot context={context} />
		</>
	);
};

export default App;
